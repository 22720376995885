<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('chart_accounts.chart_accounts') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
        </div>
      </div>

      <div class="card-body p-0">
        <div class="d-flex">
          <b-card-body class="col-md-4">
            <div class="col-lg-12 mb-5 p-0">
              <div class="input-group">
                <input type="text" class="form-control" v-model="search" :placeholder="$t('search')"/>
              </div>
              <div class="w-100 mt-4">
                <button class="btn btn-primary btn-sm text-center w-100" @click="getSubList(null, 1)">{{ $t('chart_accounts.main') }}</button>
              </div>
            </div>
            <div class="d-block free-tree">
              <v-treeview
                  :items="tree_list"
                  :search="search"
                  :filter="filter"
                  return-object
                  selected-color="warning"
                  :selection-type="selectionType"
                  hoverable
                  expand-icon="mdi-chevron-down"
                  on-icon="mdi-bookmark"
                  off-icon="mdi-bookmark-outline"
                  indeterminate-icon="mdi-bookmark-minus"
                  :active.sync="item"
                  activatable
              >
                <template v-slot:prepend="{ item, open }">
                  <v-icon v-if="!item.file">
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                  <v-icon v-else>
                    {{ files[item.file] }}
                  </v-icon>
                </template>
              </v-treeview>
            </div>

          </b-card-body>
          <b-card-body class="col-md-8">
            <div class="input-group mb-4">
              <input type="text" class="form-control" v-model="search_children" :placeholder="$t('search')"/>
              <div>
                <button class="btn btn-primary text-center mr-3 ml-3" @click="childrenSearch"><i class="fas fa-search"></i> {{ $t('search') }}</button>
              </div>
              <!--                            <div v-if="$can('chart_accounts.create') && is_collected">-->
              <div v-if="$can('chart_accounts.create')">
                <router-link :to="'/accounting/chart-accounts/create'+(parent_id? '/'+parent_id: '')" class="font-weight-bolder btn btn-primary">
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('chart_accounts.new_chart_account') }}
                </router-link>
              </div>

            </div>
            <div class="form-group overflow-y-auto" style="height: 320px;overflow-y: scroll;">

              <table class="table border">
                <tbody>

                <tr v-if="account_selected">
                  <td scope="row" style="border: 3px solid #9a9a9a;">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-icon icon="folder" font-scale="3"></b-icon>
                      </div>
                      <div style="width: 100%;padding-left: 28px;padding-right: 28px;">
                        <div class="row d-flex justify-content-between"><span><a href="javascript:;">{{ account_selected.name }} <span>#{{ account_selected.code }}</span> </a></span> <span>{{ account_selected.amount }}</span></div>
                        <div class="row d-flex justify-content-between"><span>#{{ account_selected.serial_from }}</span> <span>{{ account_selected.natural_account_name }}</span></div>
                      </div>
                      <div>
                        <b-dropdown variant="dark" class="btn-sm">
                          <template #button-content>
                            <b-icon icon="gear-fill" aria-hidden="true" font-scale="1"></b-icon>
                          </template>

                          <b-dropdown-item-button  v-if="$can('chart_accounts.journals_entries_account')" variant="info" @click="detailsItem(account_selected)">
                            <b-icon icon="eye" aria-hidden="true"></b-icon>
                            {{ $t('details') }}
                          </b-dropdown-item-button>
                          <b-dropdown-item-button v-if="$can('chart_accounts.update')" variant="primary" @click="editItem(account_selected)">
                            <b-icon icon="pencil" aria-hidden="true"></b-icon>
                            {{ $t('edit') }}
                          </b-dropdown-item-button>

                          <b-dropdown-item-button v-if="$can('chart_accounts.delete')" variant="danger" @click="deleteItem(account_selected)">
                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            {{ $t('delete') }}
                          </b-dropdown-item-button>
                        </b-dropdown>
                      </div>

                    </div>
                  </td>
                </tr>

                <tr v-for="row in sub_List" :key="row.id">
                  <td scope="row">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-icon icon="folder" font-scale="3"></b-icon>
                      </div>
                      <div style="width: 100%;padding-left: 28px;padding-right: 28px;">
                        <div class="row d-flex justify-content-between"><span><a href="javascript:;"  @click="selectItem(row)">{{ row.name }} <span>#{{ row.code }}</span> </a></span> <span>{{ row.amount }}</span></div>
                        <div class="row d-flex justify-content-between"><span>#{{ row.serial_from }}</span> <span>{{ row.natural_account_name }}</span></div>
                      </div>
                      <div>
                        <b-dropdown variant="dark" class="btn-sm">
                          <template #button-content>
                            <b-icon icon="gear-fill" aria-hidden="true" font-scale="1"></b-icon>
                          </template>

                          <b-dropdown-item-button variant="info" @click="detailsItem(row)">
                            <b-icon icon="eye" aria-hidden="true"></b-icon>
                            {{ $t('details') }}
                          </b-dropdown-item-button>
                          <b-dropdown-item-button v-if="$can('chart_accounts.update')" variant="primary" @click="editItem(row)">
                            <b-icon icon="pencil" aria-hidden="true"></b-icon>
                            {{ $t('edit') }}
                          </b-dropdown-item-button>

                          <b-dropdown-item-button v-if="$can('chart_accounts.delete')" variant="danger" @click="deleteItem(row)">
                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            {{ $t('delete') }}
                          </b-dropdown-item-button>
                        </b-dropdown>
                      </div>

                    </div>
                  </td>
                </tr>
                <tr v-if="sub_List.length <= 0">
                  <td scope="row">
                    <h5>{{ $t('chart_accounts.the_account_is_empty') }}</h5>
                  </td>
                </tr>

                <tr class="text-center" v-if="total  >= 100">
                  <td>
                    <button class="btn btn-warning" @click="loadMore">
                      <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{ $t('load_more') }}
                      </p>
                    </button>
                  </td>
                </tr>

                </tbody>
              </table>

            </div>
          </b-card-body>
        </div>


      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-chart-accounts",
  data() {
    return {
      mainRoute: 'accounting/chart-accounts',
      item: [],
      selectionType: 'independent',

      tree_list: [],
      sub_List: [],
      search: '',
      parent_id: null,
      page: 1,
      current_page: 0,
      last_page: 0,
      total: 0,
      is_collected: false,
      is_query_running: false,
      search_children: null,
      account_selected: null,
    };
  },
  computed: {
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1
    },
  },
  watch: {
    item: function (val) {
      this.sub_List = [];
      this.is_collected = false;
      if (val[0]) {
        val = val[0];
        this.account_selected = val;
        this.page = 1;
        if (val.type_account == 1) {
          this.is_collected = true;
          this.getSubList(val.id);
        } else {
          this.is_collected = false;
        }

      } else {
        this.parent_id = null;
        this.account_selected = null;
      }
    }
  },
  methods: {

    getList() {
      ApiService.get(this.mainRoute).then((response) => {
        this.tree_list = response.data.data;
      });
    },
    loadMore() {
      this.$router.push({name: 'chart-accounts-list.index', params: {parent_id: this.parent_id}})
      // this.page = this.page + 1;
      //
      // this.getSubList(this.parent_id);
    },
    getSubList(parent_id = null, page = null, filter = {}) {
      if (this.is_query_running) {
        return;
      }
      this.is_query_running = true;
      this.parent_id = parent_id;
      if (page) {
        this.page = page;
      }
      if (!parent_id && this.page == 1) {
        this.sub_List = [];
        // this.is_collected = true;
      }
      if (this.page == 1) {
        this.sub_List = [];
      }
      ApiService.get(this.mainRoute, {params: {parent_id: parent_id, is_children: 1, page: (this.page ? this.page : 1), ...filter}}).then((response) => {
        this.sub_List.push(...response.data.data.data);
        this.current_page = response.data.data.current_page;
        this.last_page = response.data.data.last_page;
        this.total = response.data.data.total;
        this.is_query_running = false;
      });
    },
    childrenSearch() {
      this.is_collected = this.parent_id ? true : false;
      this.getSubList(this.parent_id, 1, {name: this.search_children})
    },
    selectItem(row) {
      if (row.type_account == 1){
        this.item = [];
        this.item[0] = row;
      }
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getList();
        this.getSubList(this.parent_id);
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    editItem(item) {
      this.$router.push({name: 'chart-accounts.edit', params: {id: item.id}});
    },
    detailsItem(row) {
      this.$router.push({name: 'journals-entries-account.index', params: {account_id: row.id}});
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.chart_accounts")}]);
    this.getList();
  },
};
</script>

<style>
.v-treeview-node__root {
  min-height: 37px !important;
  cursor: pointer;
}

.v-treeview-node--active {
  background-color: #C9F7F5;
}
</style>
